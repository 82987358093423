import "./App.css";
import { AppContextComponent } from "./contexts/AppContext";
import { Auth0Provider } from "@auth0/auth0-react";
import { AppRouter } from "./AppRouter";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/nb";
import { HwOrderExport } from "./modules/hw/HwOrderExport";
import { B2BPortalIFrame } from "./B2BPortalIFrame";

dayjs.extend(utc);

const auth0config = {
  domain: "slog.auth0.com",
  clientId: "dOjZCcLpx6D8J2EscIwIFDsHkfOEOhq9",
};

export const PDF_EXPORT_PATH = "/pdf/";
const B2B_PORTAL_IFRAME_PATH = "/b2b/flows";

function App() {
  const path = window.location.pathname;
  const pdfExport = path.startsWith(PDF_EXPORT_PATH);
  const orderHash = pdfExport ? path.slice(PDF_EXPORT_PATH.length) : "";

  return path === B2B_PORTAL_IFRAME_PATH ? (
    <B2BPortalIFrame />
  ) : pdfExport ? (
    <HwOrderExport hash={orderHash} />
  ) : (
    <Auth0Provider domain={auth0config.domain} clientId={auth0config.clientId} authorizationParams={{ redirect_uri: window.location.origin }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
        <AppContextComponent>
          <AppRouter />
        </AppContextComponent>
      </LocalizationProvider>
    </Auth0Provider>
  );
}

export default App;
