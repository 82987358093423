import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalDialog } from "../common/components/dialogs";
import { Text2Speech } from "./tools";

type ToolModel = { id: string; name: string; path?: string; icon: string };

const tools: ToolModel[] = [
  {
    id: "text2speech",
    name: "Tekst til tale",
    icon: "text2speech.svg",
  },
];

export function ToolsModule() {
  const navigate = useNavigate();

  const [selectedTool, setSelectedTool] = useState<ToolModel | null>(null);

  return (
    <div className="flex flex-wrap p-20 gap-8">
      {selectedTool ? (
        <ModalDialog flex visible title={selectedTool.name} onClose={() => setSelectedTool(null)}>
          {selectedTool.id === "text2speech" ? <Text2Speech /> : <></>}
        </ModalDialog>
      ) : null}
      {tools.map((item) => (
        <ToolButton
          key={item.id}
          model={item}
          onClick={() => {
            if (item.path) {
              navigate(item.path);
            } else {
              setSelectedTool(item);
            }
          }}
        />
      ))}
    </div>
  );
}

function ToolButton({ model, onClick }: { model: ToolModel; onClick: () => void }) {
  return (
    <button className="flex items-center justify-center text-black rounded-r-full border-8 border-black border-2 h-32 gap-4 pr-16 ml-16" onClick={onClick}>
      <div className="flex w-32 h-32 rounded-full bg-inselo-blue -ml-16">
        <img className="h-16 w-16 m-auto" src={`/assets/images/tools/${model.icon}`} alt="" />
      </div>
      <h2 className="text-2xl inselo-blue font-bold">{model.name}</h2>
    </button>
  );
}
