import { FC, useEffect, useState } from "react";
import { TELENOR_PORTAL_SERVICES_URL } from "../../../../env";

export const RpaState: FC<{}> = () => {
  const [states, setStates] = useState<{ state: string; count: number }[]>([]);
  const [selectedState, setSelectedState] = useState<string>("");
  const [queueItems, setQueueItems] = useState<{ id: string; time: number }[]>([]);

  useEffect(() => {
    loadStates();
  }, []);

  async function loadStates() {
    setSelectedState("");
    try {
      const response = await fetch(`${TELENOR_PORTAL_SERVICES_URL}/autolog/rpa/state`);
      const data = await response.json();
      setStates(Object.keys(data).map((key: string) => ({ state: key, count: data[key] })));
    } catch (err) {
      console.log(err);
    }
  }

  async function onStateSelected(state?: string) {
    setSelectedState(state || "");
    if (state) {
      const response = await fetch(`${TELENOR_PORTAL_SERVICES_URL}/autolog/rpa/items?state=${state}`);
      const data = await response.json();
      setQueueItems(data.map((item: { tags: string[]; workTime: number }) => ({ id: item.tags[0]?.split(",")[2] || "", time: item.workTime })));
      setStates(states.map((item) => (item.state === state ? { ...item, count: data.length } : item)));
    } else {
      setQueueItems([]);
    }
  }

  return (
    <div className="flex flex-col my-4 gap-2">
      <h1 className="text-white text-2xl">RPA Queue</h1>
      {states?.length ? (
        <div className="flex gap-4">
          {states.map((item) => (
            <button className={`px-4 py-2 border-2 rounded-md ${item.state === selectedState ? "border-black text-black bg-white" : ""}`} disabled={item.count > 1000} onClick={() => onStateSelected(item.state)}>{`${item.state.toUpperCase()} (${item.count})`}</button>
          ))}
          <button onClick={loadStates}><img className="h-8 w-8" src="/assets/images/refresh.svg" /></button>
        </div>
      ) : null}
      {selectedState && queueItems.length ? (
        <div className="flex gap-4">
          {queueItems.map((item) => (
            <button className="px-4 py-2 border-2 rounded-md" disabled>{`#${item.id} (${item.time}sec)`}</button>
          ))}
          <button onClick={() => onStateSelected(selectedState)}><img className="h-8 w-8" src="/assets/images/refresh.svg" /></button>
        </div>
      ) : null}
    </div>
  );
};
