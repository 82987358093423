import { FC, useEffect, useState } from "react";
import { getData } from "../../flows/backends/AutologServicesBackend";
import { DailyStats, StatsCard } from "./StatsCard";
import { MenuItem, Select } from "@mui/material";

type FlowDailyStats = { name: string; date: string; worktime: number; count: number };

const dayLength = 24 * 60 * 60 * 1000;

function getDatesInRange(startDate: string, endDate: string) {
  const dates: string[] = [];
  const start = new Date(startDate);
  const end = new Date(endDate);
  for (let time = start.getTime(); time <= end.getTime(); time += dayLength) {
    dates.push(new Date(time).toISOString());
  }
  return dates;
}

function formatWorktime(worktimeSec: number) {
  const s = worktimeSec % 60;
  const h = Math.floor((worktimeSec - s) / 3600);
  const m = (worktimeSec - s - h * 3600) / 60;
  return `${h}:${m < 10 ? "0" + m.toString() : m}:${s < 10 ? "0" + s.toString() : s}`;
}

export const RpaByFlow: FC<{ fromDate: string; toDate: string }> = (props) => {
  const [stats, setStats] = useState<FlowDailyStats[]>([]);
  const [allFlows, setAllFlows] = useState<string[]>([]);
  const [selectedFlow, setSelectedFlow] = useState<string>();

  useEffect(() => {
    (async () => {
      const data = (await getData("autolog/stats/perflow/rpa")) as FlowDailyStats[];
      setStats(data);

      const flows: string[] = [];
      data.forEach((item) => {
        const foundFlow = flows.find((flow) => item.name === flow);
        if (!foundFlow) {
          flows.push(item.name);
        }
      });
      flows.sort((a, b) => a.localeCompare(b));
      setAllFlows(flows);
      setSelectedFlow(flows[0]);
    })();
  }, []);

  const dateRange = getDatesInRange(props.fromDate, props.toDate);
  const filteredStats: FlowDailyStats[] = dateRange.map((date) => {
    const found = stats.find((item) => item.name === selectedFlow && item.date === date);
    return {
      name: selectedFlow || "",
      date: date,
      worktime: found?.worktime || 0,
      count: found?.count || 0,
    };
  });
  const flowRpa: DailyStats[] = filteredStats.map((item) => ({ date: item.date, value: item.worktime }));
  const sumFlowRpa = filteredStats.reduce((prev, curr) => prev + curr.worktime, 0);
  const sumFlowOrders = filteredStats.reduce((prev, curr) => prev + curr.count, 0);
  const avgFlowRpa = Math.round(sumFlowRpa / filteredStats.length);
  const avgFlowRpaPerOrder = Math.round(sumFlowRpa / sumFlowOrders);

  return selectedFlow ? (
    <StatsCard
      title={
        <select className="border-2 border-black" value={selectedFlow} onChange={(e) => setSelectedFlow(e.target.value)}>
          {allFlows.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
      }
      data={flowRpa}
      stats={[
        { title: "Total", value: formatWorktime(sumFlowRpa) },
        { title: "Daily Average", value: formatWorktime(avgFlowRpa) },
        { title: "Per Order Average", value: formatWorktime(avgFlowRpaPerOrder) },
      ]}
    />
  ) : null;
};
