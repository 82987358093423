import { FC, useEffect, useState } from "react";
import { getData } from "../../flows/backends/AutologServicesBackend";
import { DailyStats, StatsCard } from "./StatsCard";
import { MenuItem, Select } from "@mui/material";

type FlowDailyStats = { name: string; date: string; count: number };

const dayLength = 24 * 60 * 60 * 1000;

function getDatesInRange(startDate: string, endDate: string) {
  const dates: string[] = [];
  const start = new Date(startDate);
  const end = new Date(endDate);
  for (let time = start.getTime(); time <= end.getTime(); time += dayLength) {
    dates.push(new Date(time).toISOString());
  }
  return dates;
}

export const OrdersByFlow: FC<{ fromDate: string; toDate: string }> = (props) => {
  const [stats, setStats] = useState<FlowDailyStats[]>([]);
  const [allFlows, setAllFlows] = useState<string[]>([]);
  const [selectedFlow, setSelectedFlow] = useState<string>();

  useEffect(() => {
    (async () => {
      const data = (await getData("autolog/stats/perflow/orders")) as FlowDailyStats[];
      setStats(data);

      const flows: string[] = [];
      data.forEach((item) => {
        const foundFlow = flows.find((flow) => item.name === flow);
        if (!foundFlow) {
          flows.push(item.name);
        }
      });
      flows.sort((a, b) => a.localeCompare(b));
      setAllFlows(flows);
      setSelectedFlow(flows[0]);
    })();
  }, []);

  const dateRange = getDatesInRange(props.fromDate, props.toDate);
  const flowOrders: DailyStats[] = dateRange.map((date) => ({ date, value: stats.find((item) => item.name === selectedFlow && item.date === date)?.count || 0 }));
  const sumFlowOrders = flowOrders.reduce((prev, curr) => prev + curr.value, 0);
  const avgFlowOrders = (sumFlowOrders / flowOrders.length).toPrecision(2);

  return selectedFlow ? (
    <StatsCard
      title={
        <select className="border-2 border-black" value={selectedFlow} onChange={(e) => setSelectedFlow(e.target.value)}>
          {allFlows.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
      }
      data={flowOrders}
      stats={[
        { title: "Total", value: sumFlowOrders.toString() },
        { title: "Daily Average", value: avgFlowOrders },
      ]}
    />
  ) : null;
};
