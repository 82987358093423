import { useContext, useState } from "react";
import Button from "../../components/common/Button";
import { ModalDialog } from "../common/components/dialogs";
import OrderModel from "../../models/OrderModel";
import { ModuleContext } from "../common/components";
import { fetchOrderHash } from "../../backend";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import { PDF_EXPORT_PATH } from "../../App";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    padding: 16,
  },
  section: {
    padding: 8,
  },
  h1: {
    fontSize: 16,
    fontWeight: "bold",
    paddingVertical: 8,
  },
  h2: {
    fontSize: 12,
    fontWeight: "bold",
    paddingVertical: 4,
  },
});

const HwOrderExportPdfContent = ({ order }: { order: OrderModel }) => {
  return (
    <PDFViewer className="flex flex-1">
      <Document>
        <Page size="A4" style={styles.page}>
          <View>
            <Image style={{ width: "120", height: "51" }} src="/assets/images/logo-telenor.png" />
            <Text style={styles.h1}>Ordrebekreftelse</Text>
            <View style={styles.section}>
              <Text style={styles.h2}>Oppdragsgiver</Text>
            </View>
            <View style={styles.section}>
              <Text>Section #2</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const HwOrderExportContent = ({ order }: { order: OrderModel }) => {
  return order ? (
    <>
      <div className="flex flex-1 bg-white rounded-md p-4">
        <div className="text-black">
          <h1>
            <img src="https://europe-west1-slog-prod.cloudfunctions.net/telenor-lightweight-api/images/telenor_logo.png" />
          </h1>
          <h1>Ordrebekreftelse</h1>
          <h2>
            <strong>Oppdragsgiver</strong>
          </h2>
          <p>
            {order.customer.name1}
            <br /> {order.customer.address1}
            <br /> {order.customer.address2}
            <br /> {order.customer.zipcode} {order.customer.city}
          </p>
          <h2>
            <strong>Varemottaker</strong>
          </h2>
          <p>
            {order.delivery.name}
            <br /> {order.delivery.address}
            <br /> {order.delivery.address2}
            <br /> {order.delivery.zipcode} {order.delivery.city}
          </p>
          <h2>
            <strong>Informasjon</strong>
          </h2>
          {/* <p>
          <strong>Dokumentnummer</strong> {{ dokument_nummer }}
          <br />
          <strong>Dokumentdato</strong> {{ dokument_dato }}
          <br />
          <strong>Valuta</strong> {{ order_valuta }}
          <br />
          <strong>Leveringsdato</strong> {{ leverings_dato }}
          <br />
          <strong>Ansvarlig selger</strong> {{ order_seller_name }}
          <br />
          <strong>Deres referanse</strong> {{ your_reference }}
          <br />
          <strong>Filial</strong> {{ order_filial }}
          <br />
          <strong>Bestillingsnummer</strong> {{ order_casenr }} <br />
          <strong>Portal ordre id</strong> {{ oid }} &nbsp;
        </p> */}
          <h2>
            <strong>Merknad</strong>
          </h2>
          <p>
            <strong>Kundereferanse</strong> {order.reference}
            <br />
            <strong>Kunderekvisisjon</strong> {order.requisition}
          </p>
          <h2>
            <strong>Artikler&nbsp;</strong>
          </h2>
        </div>
      </div>
    </>
  ) : null;
};

export const HwOrderExportButton = ({ orderId }: { orderId: string }) => {
  const processor = useContext(ModuleContext);

  const [order, setOrder] = useState<OrderModel>();

  return (
    <>
      {order ? (
        <ModalDialog visible onClose={() => setOrder(undefined)}>
          <HwOrderExportPdfContent order={order} />
        </ModalDialog>
      ) : null}
      <Button
        onClick={async () => {
          const orderHash = await processor?.run(fetchOrderHash, orderId);
          // const order = await processor?.run(fetchOrder, orderId);
          // setOrder(order);
          window.open(`${window.location.origin}${PDF_EXPORT_PATH}${orderHash}`);
        }}
      >
        Export to PDF
      </Button>
    </>
  );
};
