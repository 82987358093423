import { FC } from "react";
import { ModuleContainer } from "./modules/common/components";
import { FlowsModule } from "./modules";

export const B2BPortalIFrame: FC<{}> = (props) => {
  return (
    <ModuleContainer name="flows">
      <FlowsModule />
    </ModuleContainer>
  );
};
